import { useContext } from "react";
import { DialogContent, DialogPortal } from "@radix-ui/react-dialog";

import { AppContext } from "AppContextProvider";

import { Button } from "./auth/Button";
import config from "../config";

export const DeprecationNoticeDialog = () => {
  const { closeDeprecationPopup } = useContext(AppContext);
  return (
    <DialogPortal>
      <DialogContent className="bg-black/50 z-30 flex items-center justify-center rounded-md fixed top-0 bottom-0 left-0 right-0 p-10">
        <div className="bg-white p-6 rounded-md flex justify-center w-[420px] font-sans space-y-[10px] flex-col">
          <div className="text-lg">We have moved to a new portal</div>
          <div className="text-[14px]">
            This portal no longer accepts new orders. Please use the{" "}
            <a
              href={config.NEW_PORTAL_URL}
              target="blank"
              className="underline"
            >
              new portal
            </a>{" "}
            to place orders. The old portal will be permanently shut down on{" "}
            <strong>14 April 2025</strong>. Your existing login credentials
            remain valid.
          </div>
          <a href={config.NEW_PORTAL_URL} target="blank">
            <Button className="w-full">Go to new portal</Button>
          </a>
          <Button variant="outline" onClick={() => closeDeprecationPopup()}>
            Close
          </Button>
        </div>
      </DialogContent>
    </DialogPortal>
  );
};
