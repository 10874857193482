import { useContext, useEffect, useState } from "react";
import {
  ContainerOutlined,
  FileAddOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { Layout, Menu, theme } from "antd";
import { Dialog } from "@radix-ui/react-dialog";

import { AppContext } from "AppContextProvider";
import { conduktClient } from "clients/condukt";

import Tabs from "./create-request";
import OrdersHistory from "./history";
import { HiddenSettings } from "./settings/HiddenSettings";
import { DeprecationNoticeDialog } from "./DeprecationNoticeDialog";
import useHasPermission, { PERMISSIONS } from "../clients/useHasPermission";
import config from "../config";

const { Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items = [
  getItem("New Request", "newRequest", <FileAddOutlined />),
  getItem("History", "pastRequests", <ContainerOutlined />),
  getItem("Logout", "logout", <LogoutOutlined />),
];

const App = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { menuItem, setMenuItem, setJwt, isDeprecationPopupShown } =
    useContext(AppContext);
  const {
    token: { borderRadiusLG },
  } = theme.useToken();
  const hiddenSettingPerm = useHasPermission(PERMISSIONS.HIDDEN_SETTING);

  useEffect(() => {
    const getJWTToken = async () => {
      const jwt = await conduktClient.getJWTToken();
      setJwt(jwt);
    };
    getJWTToken();
  }, []);

  const getMenuHeader = () => {
    switch (menuItem) {
      case "newRequest":
        return "New request";
      case "pastRequests":
        return "History";
      default:
        break;
    }
  };

  const getComponent = () => {
    switch (menuItem) {
      case "newRequest":
        return <Tabs />;
      case "pastRequests":
        return <OrdersHistory />;

      default:
        break;
    }
  };

  const onClickMenuItem = async ({ key }) => {
    if (key === "logout") {
      if (conduktClient.refreshToken) {
        await conduktClient.logOut();
      }
      delete localStorage.refreshToken;
      delete localStorage.username;
      window.location.reload();
    }
    setMenuItem(key);
  };

  const curDate = new Date();
  const username = localStorage.getItem("username");

  return (
    <div className="flex flex-col">
      <Dialog open={!isDeprecationPopupShown}>
        <DeprecationNoticeDialog />
        {isDeprecationPopupShown && (
          <div className="w-screen bg-primary text-center font-sans py-4">
            This portal no longer accepts new orders. Please use the{" "}
            <a
              href={config.NEW_PORTAL_URL}
              target="blank"
              className="underline"
            >
              new portal
            </a>{" "}
            to place orders. The old portal will be permanently shut down on{" "}
            <strong>14 April 2025</strong>. Your existing login credentials
            remain valid.
          </div>
        )}
        <div className="flex h-full">
          <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            trigger={
              <>
                {collapsed ? (
                  <img style={{ width: 14 }} src="arrow-right.png" alt="" />
                ) : (
                  <img style={{ width: 14 }} src="arrow-left.png" alt="" />
                )}
              </>
            }
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: 50,
                margin: "10%",
              }}
            >
              {hiddenSettingPerm ? (
                <HiddenSettings>
                  {collapsed ? (
                    <img src="/condukt-logo-short.png" alt="" />
                  ) : (
                    <img
                      style={{
                        maxWidth: "100%",
                        objectFit: "contain",
                      }}
                      src="/condukt-logo-full.png"
                      alt=""
                    />
                  )}
                </HiddenSettings>
              ) : (
                <img
                  style={{
                    maxWidth: "100%",
                    objectFit: "contain",
                  }}
                  src="/condukt-logo-full.png"
                  alt=""
                />
              )}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                color: "white",
              }}
            >
              {!collapsed && username && <p>Hello, {`${username}`}</p>}
            </div>

            <Menu
              selectedKeys={[menuItem]}
              mode="inline"
              items={items}
              onClick={onClickMenuItem}
            />
          </Sider>
          <Layout>
            <div
              style={{
                display: "flex",
                padding: "0 24px",
                margin: "0 16px",
              }}
            >
              <h1 style={{ color: "white" }}>{getMenuHeader()}</h1>
            </div>

            <Content
              style={{
                margin: "0 16px",
              }}
            >
              <br />
              <div
                style={{
                  padding: 24,
                  minHeight: 360,
                  borderRadius: borderRadiusLG,
                }}
              >
                {getComponent()}
              </div>
            </Content>

            <Footer className="centered">
              Condukt © {curDate.getFullYear()}
            </Footer>
          </Layout>
        </div>
      </Dialog>
    </div>
  );
};
export default App;
